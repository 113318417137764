import {BaseResponse, METHODS, request} from '@/utils/request';
import {User} from "@/model/User";

export function userLoginApi(data: any) {
  let params = ''
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
    if (params != '') {
      params += '&'
    }
    params += key + '=' + data[key]
  }
  return request<BaseResponse>({
    baseURL: '/single/security/user/login',
    method: METHODS.POST,
    data: formData,
  }, {
    isDataDirectly: false
  })
}

export function userTicketLoginApi(data: any) {
  return request<BaseResponse>({
    baseURL: '/single/security/ticket.html?ticket=' + data,
    method: METHODS.GET,
    data: data
  }, {
    isDataDirectly: false
  })
}

export function registerApi(data: any) {
  return request<BaseResponse>({
    baseURL: '/single/wechat/register?code=' + data.code + '&state=' + data.state,
    method: METHODS.POST
  }, {
    isDataDirectly: false
  })
}

export function validateApi(data: any) {
  return request<BaseResponse>({
    baseURL: '/single/security/validate.html',
    method: METHODS.GET
  }, {
    isDataDirectly: false
  })
}

export function logoutApi(data: Object = {}) {
  return request<BaseResponse>({
    baseURL: '/single/security/user/logout',
    method: METHODS.POST,
    data
  }, {
    isDataDirectly: false
  })
}

export function listRmsUserPageApi(data: Object = {}) {
  return request<BaseResponse>({
    baseURL: '/single/rms/user/listRmsUserPage',
    method: METHODS.POST,
    data
  }, {
    isDataDirectly: false
  })
}

export function insertUserApi(data: Object = {}) {
  return request<BaseResponse>({
    baseURL: '/single/rms/user/addRmsUser',
    method: METHODS.POST,
    data
  }, {
    isDataDirectly: false
  })
}

export function modifyUserApi(data: Object = {}) {
  return request<BaseResponse>({
    baseURL: '/single/rms/user/modifyRmsUser',
    method: METHODS.POST,
    data
  }, {
    isDataDirectly: false
  })
}

export function resetApi(data: Object = {}) {
  return request<BaseResponse>({
    baseURL: '/single/rms/user/reset?id=' + data,
    method: METHODS.POST
  }, {
    isDataDirectly: false
  })
}

export function buildLoginSateApi(data: Object = {}) {
  return request<BaseResponse>({
    baseURL: '/single/wechat/buildLoginSate',
    method: METHODS.POST
  }, {
    isDataDirectly: false
  })
}

export function sendMessageApi(data: User = {}) {
  return request<BaseResponse>({
    baseURL: '/single/rms/user/sendMessage',
    method: METHODS.POST,
    data
  }, {
    isDataDirectly: false
  })
}


export function changePasswordApi(data: User = {}) {
  return request<BaseResponse>({
    baseURL: '/single/rms/user/changePassword',
    method: METHODS.POST,
    data
  }, {
    isDataDirectly: false
  })
}
